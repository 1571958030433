#language-changer {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 0 10px 0 10px;
}

#language-changer:hover {
  cursor: pointer;
}

#flag {
  font-size: 30px;
  font-weight: 500;
  color: white;
  padding: 0 10px 0 10px;
}

select,
select::after,
select::before,
select:active {
  background-color: rgba(0, 0, 0, 0.2);
  border-style: none;
  color: white;
  font-size: 25px;
  border-radius: 10px;
  padding: 10px 5px 10px 10px;
  border-right: 10px solid transparent
}