#footer {
  position: absolute;
  right: 40px;
  bottom: 40px;
  font-size: 15px;
}

#footer a {
  color: lightblue;
  text-decoration: none;
}

@media (max-width:960px) {
  #footer {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 50px;
  }
}