.content {
  background-color: #0066c7;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: auto;
  min-height: 100vh;
  width: 100%;
}

.mainTitle {
  text-align: center;
  font-size: calc(20px + 1vw);
  font-weight: bold;
  color: white;
  margin: 80px 0 80px 0;
}

.prompt {
  text-align: center;
  font-size: 24px;
  height: auto;
  font-weight: bold;
  color: white;
  width: 70%;
  margin: 40px 0 30px 0;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}

::placeholder {
  color: #BFBFBF;
  opacity: 1;
}

.middleBox {
  border-radius: 6px;
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #005BB2;
  height: auto;
  width: 50%;
}

.searchBox {
  height: 90px;
  width: 85%;
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
  border-radius: 6px;
  border-style: none;
  outline: none;
  outline-style: none;
}

.button {
  display: flex;
  height: 90px;
  width: 85%;
  background-color: #004587;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  border-style: none;
  border-radius: 6px;
  margin-top: 15px;
  margin-bottom: 40px;
  box-shadow: 0 4px rgba(0, 0, 0, 0.4);
}

.acknowledgeButton {
  bottom: 30px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  height: 90px;
  width: 85%;
  border-style: none;
  border-radius: 6px;
  margin: 0px 40px 0px 40px;
}

.neutal {
  opacity: 0;
}

.negative {
  border-radius: 6px;
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #78CD24;
  height: auto;
  padding: 40px 0 40px 0;
  width: 40%;
  color: white;
  text-align: center;
  justify-content: center;
}

.positive {
  border-radius: 6px;
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 40px 0;
  background-color: #FFFC45;
  height: auto;
  width: 40%;
  color: black;
  text-align: center;
  justify-content: center;
}

.invalid {
  border-radius: 6px;
  z-index: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 40px 0;
  background-color: #d21b46;
  height: auto;
  width: 40%;
  color: white;
  text-align: center;
  justify-content: center;
}

.mainOutput {
  font-size: 25px;
  width: 70%;
  font-weight: bold;
}

.secondaryOutput {
  font-size: 21px;
  width: 70%;
  width: 90%;
}

.centeredContent {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  color: white
}

.button:active {
  box-shadow: 0 2px rgba(0, 0, 0, 0.6);
  transform: translateY(4px);
}

.warning {
  color: white;
  margin: 0 40px 30px 40px;
  text-align: center;
  font-size: 21px;
  width: 80%;
}

@media (max-width:960px) {

  .content {
    height: auto;
  }

  .copyright {
    position: inherit;
    margin: 60px 10px 30px 0;
    font-size: 12px;
  }

  .mainTitle {
    margin: 50px 30px 50px 30px;
  }

  #logo {
    width: 50%;
  }

  .middleBox,
  .positive,
  .negative {
    border-radius: 0;
    width: 100%;
  }

  .prompt {
    width: 80%;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .warning {
    width: 80%;
    font-size: 18px;
  }

  .form {
    margin-top: 10px;
  }

  .button,
  .acknowledgeButton,
  .searchBox {
    font-size: 18px;
    height: 50px;
    padding: 0;
  }
}