#navigation-bar {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding-top: 50px;
}

#logo-parent {
  width: auto;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

#logo {
  width: 250px;
  height: auto;
}

@media (max-width:960px) {
  #logo {
    width: 150px;
  }
}