#error-background {
  height: 100vh;
  width: 100vw;
  background-color: #0066c7;
  color: white;
  padding: 0;
  margin: 0;
}

#error-background h1 {
  padding: 50px;
}